var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message-detail"},[(_vm.$route.params.type !== 1)?_c('div',{staticStyle:{"padding-top":"10px"}},[_c('div',{class:{
        status: true,
        wait_color: _vm.info.approvalStatus == 0 || _vm.info.approvalStatus == 4,
        refuse_color: _vm.info.approvalStatus == 3 || _vm.info.approvalStatus == 2,
        pass_color: _vm.info.approvalStatus == 1,
      }},[_vm._v(" "+_vm._s(_vm.info.approvalStatus == 0 ? "待处理" : _vm.info.approvalStatus == 1 ? "已通过" : _vm.info.approvalStatus == 2 ? "未通过" : _vm.info.approvalStatus == 3 ? "课时已被调整" : _vm.info.approvalStatus == 4 ? "已撤回" : "")+" ")]),_c('div',{staticClass:"info"},[_c('ul',[_c('li',{staticClass:"title"},[_vm._v(_vm._s(_vm.info.groupName))]),_c('li',[_vm._m(0),_vm._v(" "+_vm._s(_vm.info.studentName)+" ")]),_c('li',[_c('span'),_vm._v(" "+_vm._s(_vm.info.date)+" "+_vm._s(_vm.info.week)+" ")]),_c('li',{staticClass:"approver"},[_c('p',[_vm._v("审批人")]),_c('p',[_vm._v(_vm._s(_vm.info.approver || "排课班主任"))])])])]),_c('div',{staticClass:"content"},[_c('ul',[_c('li',[_c('span',[_vm._v("课时调整：")]),_c('span',{staticClass:"key"},[_vm._v(_vm._s(_vm.info.operationType == 1 ? "请假并改期" : _vm.info.operationType == 2 ? "请假" : "预约上课"))])]),_c('li',[_c('span'),_c('span',{staticClass:"key"},[_vm._v(_vm._s(_vm.info.operationType == 1 ? "将这节课改至其他时间上课" : ""))])]),_c('li',[_c('span',[_vm._v("原上课时间：")]),_c('span',{staticClass:"key"},[_vm._v(_vm._s(_vm.info.operationType == 3 ? "" : _vm.info.oldDateTime))])]),_c('li',[_c('span',[_vm._v("新上课时间：")]),_c('span',{staticClass:"key"},[_vm._v(_vm._s(_vm.info.newDateTime))])]),_c('li',[_c('span',[_vm._v("原因说明：")]),_c('div',{staticClass:"remarks"},[_vm._v(" "+_vm._s(_vm.info.reason)+" ")])]),_c('li',{staticClass:"line"}),_c('li',[_c('span',[_vm._v("提交时间：")]),_c('span',{staticClass:"key"},[_vm._v(_vm._s(_vm.info.createTime))])])])]),(
        _vm.info.approvalStatus != 3 &&
        _vm.info.approvalStatus != 1 &&
        _vm.info.approvalStatus != -1
      )?_c('button',{staticClass:"revoke_btn",on:{"click":_vm.revoke}},[_vm._v(" "+_vm._s(_vm.info.approvalStatus == 2 || _vm.info.approvalStatus == 4 ? "重新提交" : "撤回")+" ")]):_vm._e()]):_c('div',[_c('div',{staticClass:"system_message"},[_c('p',[_vm._v(_vm._s(_vm.$route.params.title))]),_c('p',[_vm._v(_vm._s(_vm.$route.params.time))]),_c('div',[_c('pre',[_vm._v(_vm._s(_vm.$route.params.content))])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('img',{attrs:{"src":require("../../../assets/images/personnel.png"),"alt":""}})])}]

export { render, staticRenderFns }